<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form ref="form" class="row">
      <input-text
        v-model="form.nome"
        class="col-12 col-md-6"
        :label="$t('modulos.setor.formulario.nome')"
        obrigatorio
      />
      <input-select
        v-model="form.tipoSetorId"
        :loading="loadingTipoSetor"
        :options="opcoes.tipoSetor"
        class="col-12 col-md-2"
        :label="$t('modulos.setor.formulario.tipoSetorId')"
        obrigatorio
      />
      <input-select
        v-model="form.localEstocagemId"
        :loading="loadingLocalEstocagem"
        :options="opcoes.localEstocagem"
        class="col-12 col-md-2"
        :label="$t('modulos.setor.formulario.localEstocagemId')"
      />
      <input-text
        v-model="form.sigla"
        class="col-12 col-md-2"
        :label="$t('modulos.setor.formulario.sigla')"
        :obrigatorio="participanteLogadoConta"
      />
      <input-text
        v-model="form.observacao"
        class="col-12 col-md-8"
        :label="$t('modulos.setor.formulario.observacao')"
      />
      <input-radio
        v-model="form.flagExecutaManutencao"
        class="col-12 col-md-2"
        :options="opcoes.booleano"
        :label="$t('modulos.setor.formulario.executa_manutencao')"
        :divider="false"
      />
      <input-radio
        v-model="form.flagExecutaCalibracao"
        class="col-12 col-md-2"
        :options="opcoes.booleano"
        :label="$t('modulos.setor.formulario.executa_calibracao')"
        :divider="false"
      />
      <accordion-padrao
        :titulo="$t('modulos.setor.formulario.temperatura.titulo')"
        :nao-retrair="true"
        class="col-12 col-md-4"
      >
        <div class="row">
          <input-text
            v-model="form.temperatura"
            class="col-12 col-md-6"
            :label="$t('modulos.setor.formulario.temperatura.valor')"
            type="number"
          />
          <input-text
            v-model="form.temperaturaVariacao"
            class="col-12 col-md-6"
            :label="$t('modulos.setor.formulario.temperatura.variacao')"
            type="number"
          />
        </div>
      </accordion-padrao>
      <accordion-padrao
        :titulo="$t('modulos.setor.formulario.umidadeRelativa.titulo')"
        :nao-retrair="true"
        class="col-12 col-md-4"
      >
        <div class="row">
          <input-text
            v-model="form.umidadeRelativa"
            class="col-12 col-md-6"
            :label="$t('modulos.setor.formulario.umidadeRelativa.valor')"
            type="number"
          />
          <input-text
            v-model="form.umidadeRelativaVariacao"
            class="col-12 col-md-6"
            :label="$t('modulos.setor.formulario.umidadeRelativa.variacao')"
            type="number"
          />
        </div>
      </accordion-padrao>
      <accordion-padrao
        :titulo="$t('modulos.setor.formulario.pressaoAtm.titulo')"
        :nao-retrair="true"
        class="col-12 col-md-4"
      >
        <div class="row">
          <input-text
            v-model="form.pressaoAtmosferica"
            class="col-12 col-md-6"
            :label="$t('modulos.setor.formulario.pressaoAtm.valor_hpa')"
            type="number"
          />
          <input-text
            v-model="form.pressaoAtmosfericaVariacao"
            class="col-12 col-md-6"
            :label="$t('modulos.setor.formulario.pressaoAtm.variacao_hpa')"
            type="number"
          />
        </div>
      </accordion-padrao>
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        :disabled="!valido"
        :tooltip="tooltipBotaoSalvar"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import SetorService from '@common/services/cadastros/SetorService';
import LocalEstocagemService from '@common/services/cadastros/LocalEstocagemService';
import TipoSetoresService from '@common/services/cadastros/TipoSetoresService';
import { SetorModel } from '@common/models/cadastros/SetorModel.js';
export default {
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new SetorModel({}),
      opcoes: {
        tipoSetor: [],
        localEstocagem: [],
        booleano: helpers.BoleanoEnum,
      },
      loadingTipoSetor: false,
      loadingLocalEstocagem: false,
      flagAtivoUsuario: false,
      participanteLogadoConta:
        this.$store.getters['Autenticacao/participanteLogadoConta'],
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.setor.titulos.editar');
      return this.$t('modulos.setor.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.setor.validacoes.formulario_invalido');
    },
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },
  mounted() {
    this.flagAtivoUsuario = localStorage.getItem('flagAtivoUsuario');
    this.buscarLocalEstocagem();
    this.buscarTipoSetor();
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Setor', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Setor', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    regraVariacaoMaiorQueZero: function (formValue) {
      if (!formValue) return [];

      const regraValor = (v) => {
        if (parseFloat(v) < 0)
          return this.$t('geral.erros.campoDeveSerMaiorQueZero');
        return true;
      };
      return [regraValor];
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      SetorService.buscar(this.id)
        .then((res) => {
          this.form = new SetorModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.setor.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarTipoSetor: function () {
      this.loadingTipoSetor = true;
      TipoSetoresService.listar().then((res) => {
        this.opcoes.tipoSetor = new DropdownModel(res.data.items, 'descricao');
      });
      this.loadingTipoSetor = false;
    },
    buscarLocalEstocagem: function () {
      this.loadingLocalEstocagem = true;
      LocalEstocagemService.listar().then((res) => {
        this.opcoes.localEstocagem = new DropdownModel(res.data);
      });
      this.loadingLocalEstocagem = false;
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      SetorService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.setor.cadastro_sucesso`));
          this.$router.push({ name: 'setor' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'setor' });
      });
    },
  },
};
</script>
