import _ from 'lodash';

export class SetorModel {
  constructor({
    id,
    nome,
    tipoSetorId,
    sigla,
    localEstocagemId,
    observacao,
    temperatura,
    temperaturaVariacao,
    umidadeRelativa,
    umidadeRelativaVariacao,
    pressaoAtmosferica,
    pressaoAtmosfericaVariacao,
    flagExecutaManutencao = false,
    flagExecutaCalibracao = false
  }) {
    this.id = id;
    this.nome = nome;
    this.tipoSetorId = tipoSetorId;
    this.sigla = sigla;
    this.localEstocagemId = localEstocagemId;
    this.observacao = observacao;
    this.temperatura = temperatura;
    this.temperaturaVariacao = temperaturaVariacao;
    this.umidadeRelativa = umidadeRelativa;
    this.umidadeRelativaVariacao = umidadeRelativaVariacao;
    this.pressaoAtmosferica = pressaoAtmosferica;
    this.pressaoAtmosfericaVariacao = pressaoAtmosfericaVariacao;
    this.flagExecutaManutencao = flagExecutaManutencao;
    this.flagExecutaCalibracao = flagExecutaCalibracao;
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.temperatura = parseFloat(this.temperatura);
    retorno.temperaturaVariacao = parseFloat(this.temperaturaVariacao);
    retorno.umidadeRelativa = parseFloat(this.umidadeRelativa);
    retorno.umidadeRelativaVariacao = parseFloat(this.umidadeRelativaVariacao);
    retorno.pressaoAtmosferica = parseFloat(this.pressaoAtmosferica);
    retorno.pressaoAtmosfericaVariacao = parseFloat(
      this.pressaoAtmosfericaVariacao
    );

    return retorno;
  }
}
